.dontShowCard {
    display: none;
}

.cardz {
    color: #000;
    height: $card-height;
    width: $card-width;

    position: relative;
    perspective: 600px;

    // overlap cards
    margin-left: $card-width / -1.4;

    .front,
    .back {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 0 5px rgba(lighten(#000, 5%), 0.5);
        position: absolute;
        top:0;
        left:0;
        width:inherit;
        height:inherit;
        transform-style: preserve-3d;
        backface-visibility: hidden;
    }

    .front {
        display: flex;
        flex-direction: column;
        z-index: 3;
        transform: rotateX(0) rotateY(0);
        // hide suit (example S, C,...)
        // presentation wll be replaced by
        // symbols defined here
        .suit {
            font-size:0;
        }
        .section {
            &.top,
            &.bottom {
                flex: 0 1 auto;
                padding-top: 2px;
                span {
                    display: block;
                    font-weight: bold;
                    line-height: 24px;
                    height:24px;
                    &.rank {
                        font-size: 18px;
                    }
                    &.suit {
                        padding-left: 1px;
                        &:after {
                            font-size: 22px;
                        }
                    }
                }
            }

            &.center {
                flex: 1 1 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                &:after {
                    font-size: 34px;
                }
            }

            &.bottom {
                transform: rotate(180deg);
            }

            // align properly rank and suit
            // at the top and the bottom
            .containers {
                max-width: 25%;
                text-align: center;
            }
        }
    }

    .back {
        z-index: 2;
        transform: rotateY(-179deg);

        border: 10px solid #fff;
        // pattern from http://lea.verou.me/css3patterns/#argyle
        background-color: #6d695c;
        background-image:
        repeating-linear-gradient(120deg, rgba(255,255,255,.1), rgba(255,255,255,.1) 1px, transparent 1px, transparent 60px),
        repeating-linear-gradient(60deg, rgba(255,255,255,.1), rgba(255,255,255,.1) 1px, transparent 1px, transparent 60px),
        linear-gradient(60deg, rgba(0,0,0,.1) 25%, transparent 25%, transparent 75%, rgba(0,0,0,.1) 75%, rgba(0,0,0,.1)),
        linear-gradient(120deg, rgba(0,0,0,.1) 25%, transparent 25%, transparent 75%, rgba(0,0,0,.1) 75%, rgba(0,0,0,.1));
        background-size: 70px 120px;
    }

    // color for hearts and diamonds
    &.H,
    &.D {
        color: #e40909;
    }
    // symbols for each suit
    &.H .suit:after {
        content: '\2665';
    }
    &.S .suit:after {
        content: '\2660';
    }
    &.C .suit:after {
        content: '\2663';
    }
    &.D .suit:after {
        content: '\2666';
    }
    // guarantees some randomness
    // in card rotation
    &.H {
        transform: $card-rotate-hearts;
    }
    &.C {
        transform: $card-rotate-clubs;
    }
    &:nth-child(odd) {
        transform: $card-rotate-odd;
    }
    &:nth-child(even) {
        transform: $card-rotate-even;
    }

    // facedown card
    &[data-private="true"] {
        .front {
            transform: rotateY(179deg);
        }
        .back {
            transform: rotateX(0) rotateY(0);
        }
    }
}

@media #{$tablet} {
    .card {
        height: $card-height-smaller;
        width: $card-width-smaller;
        margin-left: $card-width-smaller/-1.4;
    }
}
@media #{$phone} {
    .card {
        height: $card-height-small;
        width: $card-width-small;
        margin-left: $card-width-small/-1.4;
        .front {
            .section {
                &.top,
                &.bottom {
                    span {
                        line-height: 16px;
                        height:16px;
                        &.rank {
                            font-size: 14px;
                        }
                        &.suit {
                            &:after {
                                font-size: 14px;
                            }
                        }
                    }
                }
                &.center {
                    &:after {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}


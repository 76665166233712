// html {
//     overflow: hidden;
// }

html,
body,
#root,
.app {
    width: 100%;
    height: 100%;
    min-width: 320px;
}

.app {
    display: flex;
    flex-direction: column;
    background-image: url('./gorillatransparent30.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 600px auto;
    background-position: 50% 28%;

    // header{
    //     display: flex;
    //     justify-content: center;
    //     flex: 0 0 auto;
    // }
    // header {
    //     // flex: 0 0 auto;
    // }
    >section {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .hand {
            flex: 1 0 50%;
            display: flex;
            flex-direction: column;
        }
    }

    footer {
        flex: 0 0 auto;
    }

    overflow: hidden;
}

.gorilla {
    max-width: 100%;
    height: auto;
    opacity: .3;
}


@media #{$tablet-down} and (min-height: 580px) {
    .app>section {
        flex-direction: column;
    }
}

.headerInfo {
    position: fixed;
}
$primary-color: #114602;
$secondary-color: #EEE8AA;
$success-color: #008000;
$danger-color: #f00;
$neutral-info-color: #808080;

$body-bg-color: $primary-color;

// Fonts
$body-font-family: "Century Gothic", Futura, sans-serif;
;
$text-color: $secondary-color;

$button-primary-bg: #90b6dc;
$button-secondary-bg: #f26348;

// card (hand)
$card-enter-duration: 0.8s;
$card-deal-delay: 0.25s;
$card-flip-delay: 0.15s;
$card-deal-bezier: cubic-bezier(0.16, 0.84, 0.32, 1.04);
$card-rotate-hearts: rotate(-1deg);
$card-rotate-clubs: rotate(1deg);
$card-rotate-odd: rotate(-2deg);
$card-rotate-even: rotate(2deg);

$card-height: 148px;
$card-width: 108px;
$card-height-smaller: $card-height/1.4;
$card-width-smaller: $card-width/1.4;
$card-height-small: $card-height/1.6;
$card-width-small: $card-width/1.6;


// score (hand)
$score-enter-duration: 0.2s;
$score-enter-delay: 0.3s;
$score-leave-duration: 0.1s;
$score-height: 40px;

// outcome (info)
$outcome-enter-duration: 0.3s;
$outcome-enter-delay: 0.6s;
$outcome-leave-duration: 0.2s;

// media queries
// $tablet-min: 411px;
// $tablet-max: 800px;
// $phone-max: 410px;

$tablet-min: 511px;
$tablet-max: 800px;
$phone-max: 510px;

$retina: "only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx)";
$tablet: "only screen and (min-width: #{$tablet-min}) and (max-width: #{$tablet-max})";
$tablet-down: "only screen and (max-width: #{$tablet-max})";
$phone: "only screen and (max-width: #{$phone-max})";

// various
$bezier: cubic-bezier(0.16, 0.84, 0.32, 1.24);

// COLORS
$color-primary: rgba(2, 83, 88, 0.99);
$color-primary-light: rgba(2, 83, 88, 0.99);
$color-primary-dark: rgba(21, 4, 32, 0.94);

$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;


// FONT
$default-font-size: 1.6rem;


// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;

.marginRight {
  margin-right: 40px;
}

.desktopItem {
    display: block;
}

.mobileItem {
    display: none;
}

.controls {
    width: 100%;
    height: 140px;
    position: relative;
    display: flex;
    justify-content: space-around;

    .button-container {
        width:100%;
        height:100%;
        text-align: center;
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .customButton {
        margin:0;
        border:0;
        padding:0;
        display:inline-block;
        vertical-align:middle;
        white-space:normal;
        line-height:1;
        width:60px;
        height:60px;
        border-radius: 60px;
        border: 2px solid #fff;
        font-size: 24px;
        background: $button-primary-bg;
        color: #fff;
        line-height: 1;
        position: relative;
        margin-right: 8px;
        cursor: pointer;
        &:focus {
            outline:0;
        }
        &:disabled {
            opacity: 0.2;
        }
        &:active {
            transform: translate3d(0, 1px, 0);
        }
        &:last-child {
            margin-right:0;
        }
        &.deal {
            animation: pulse 4s 2s infinite cubic-bezier(0.66, 0, 0, 1);
            &:hover {
                animation: none;
            }
        }
        @keyframes pulse {
            0% { box-shadow: 0 0 0 0 rgba($button-primary-bg, 0.7); }
            // animation ends here (creates delay between keyframes)
            50% { box-shadow: 0 0 0 45px rgba(darken($button-primary-bg, 30%), 0); }
            100% { box-shadow: 0 0 0 45px rgba(darken($button-primary-bg, 30%), 0); }
        }
        &.stand {
            background: $button-secondary-bg;
        }
        span {
            position: absolute;
            bottom:-20px;
            width:100%;
            left:0;
            right:0;
            font-size: 14px;
            text-shadow: 0 1px 0 rgba(lighten(#000, 5%), 0.6);
        }
    }

    // animations for control buttons
    .button-container {
        button {
            transition: transform 0.2s $bezier;
            &:nth-child(2) {
                transition-delay: 0.1s;
            }
        }
        &.buttons-enter {
            button {
                transition-delay: 0.3s;
                &:nth-child(2) {
                    transition-delay: 0.2s;
                }
            }
        }
        &.buttons-enter,
        &.buttons-leave.buttons-exit-active  {
            button {
                transform: translate3d(0, 300px, 0);
            }
        }
        &.buttons-enter.buttons-enter-active,
        &.buttons-exit  {
            button {
                transform: translate3d(0, 0, 0);
            }
        }
    }

    // table edge (wood)
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        // blend with the inset box-shadow
        z-index:-1;
        height: 100%;
        bottom: 0;top:0;
        left:0;right:0;
        box-shadow: -1px -6px 16px -4px rgba(lighten(#000, 1%), 0.8);
        // show bg-color while the image is loading
        background-color: #271a11;
        background-repeat: repeat;
        background-image: url('./images/wood.jpg');
        @media #{$retina} {
            background-size: 200px 169px;
        }
    }
}

@media #{$tablet} {
    .controls {
        height: 120px;
    }
}

@media #{$phone} {
    .controls {
        .desktopItem {
            display: none;
        }
        .mobileItem{
            display: block;
        }
        .marginRight {
            margin-right: 10px;
        }
    }
}


.section-about {
    background-color: $color-grey-light-1;
    padding: 25rem 0;
    margin-top: -20vh;

    @include respond(tab-port) {
        padding: 20rem 0;
    }
}

.section-features {
    background-image: linear-gradient(0deg, rgba(21, 4, 32, 0.94) 0%, rgba(2, 83, 88, 0.99) 88%);
    background-size: cover;
    transform: skewY(-7deg);
    margin-top: -10rem;

    &>* {
        transform: skewY(7deg);
    }
}

.section-tours {
    background-color: $color-grey-light-1;
    padding: 25rem 0 10rem 0;
    margin-top: -10rem;

    @include respond(tab-port) {
        padding: 20rem 0 10rem 0;
    }

    height: auto;
}

.section-stories {
    position: relative;
    padding: 15rem 0;

    @include respond(tab-port) {
        padding: 10rem 0;
    }
}

.section-book {
    padding: 15rem 0;
    background-image: linear-gradient(0deg, rgba(21, 4, 32, 0.94) 0%, rgba(2, 83, 88, 0.99) 88%);

    @include respond(tab-port) {
        padding: 10rem 0;
    }
}

.book {
    background-image: linear-gradient(105deg,
            rgba($color-white, .9) 0%,
            rgba($color-white, .9) 50%,
            transparent 50%);
    background-size: 100%;
    border-radius: 3px;
    box-shadow: 0 1.5rem 4rem rgba($color-black, .2);

    @include respond(tab-land) {
        background-image: linear-gradient(105deg,
                rgba($color-white, .9) 0%,
                rgba($color-white, .9) 65%,
                transparent 65%);
        background-size: cover;
    }

    @include respond(tab-port) {
        background-image: linear-gradient(to right,
                rgba($color-white, .9) 0%,
                rgba($color-white, .9) 100%);
    }

    &__form {
        width: 50%;
        padding: 6rem;

        @include respond(tab-land) {
            width: 65%;
        }

        @include respond(tab-port) {
            width: 100%;
        }
    }
}
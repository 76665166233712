@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap');

.features {
  font-family: 'Montserrat', sans-serif;
  padding-top: 40px;
  margin-top: 40px !important;
  padding-bottom: 40px;
}

@media (min-width: 1100px) {
  .features {
    padding-top: 100px;
    margin-top: -140px !important;
  }
}

.headerFeature {
  color: #fff;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 60px;

  h1 {
    font-size: 4rem !important;
    font-weight: 600;
  }
}

.checkMarkContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: -30px;
  padding-top: 30px;
}

.checkMarkContainer:first-child {
  padding-top: 0px;
}

.mainContainer {

  max-width: 70%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  text-align: left;

  h1 {
    font-weight: 900;
    padding-bottom: 30px;
  }

  p {
    font-size: 2.4rem;
  }

  img {
    max-width: 9%;
  }

  span {
    font-weight: 700;
  }
}


@media (min-width: 1100px) {
  .mainContainer {
    max-width: 680px;

    h1 {
      font-weight: 900;
    }

    p {
      font-size: 2.4rem;
    }

    img {
      padding-top: 10px;
    }

  }
}
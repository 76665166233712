.movesContainer {
  margin-top: 20px;
}

.mistakes {
  border-bottom: 1px solid #eee;
  padding-bottom: 6px;
  padding-top: 6px;
}

.mistakes:last-child {
  border-bottom: none;
}
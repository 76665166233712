@import '../../css/abstracts/hp-variables.scss';

.seafoamGreen {
  background-color: #B8FFF2;
  padding: 20px;
  color: #000;
  border-radius: 2px;
  margin-bottom: 20px;

  .paragraph {
    font-size: 2.4rem;
    font-weight: 600;
  }

  .bold {
    font-weight: 900;
  }
}

.headingPrimary {
  color: $color-white;
  text-transform: uppercase;

  backface-visibility: hidden;
  margin-bottom: 6rem;
}


.headingSecondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(to right, $color-primary-light, $color-primary-dark);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: .2rem;
  transition: all 1s;
}

.headingTertiary {
  font-size: $default-font-size;
  font-weight: 700;
  text-transform: uppercase;
}

.paragraph {
  font-size: 2rem;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}


.card {
  width: 90%;
  margin: 0 auto;
}

.customCard {
  height: auto;
  max-width: 100%;
  margin: 0 auto;
  height: auto;
  transition: all 3s ease;
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba($color-black, .15);
  background-color: #fff;
  padding-top: 40px;

  &__details {
    padding: 4rem;
    padding-left: 5rem;
    padding-right: 5rem;
  }

      .discordWidget {
        padding-top: 40px;
        text-align: center;

        >iframe {
          width: 250px;
          height: 370px;
        }
      }

  //@include respond(tab-port) {
  @media only screen and (max-width: 56.25em),
  only screen and (hover: none) {

    height: auto;
    border-radius: 3px;
    background-color: $color-white;
    box-shadow: 0 1.5rem 4rem rgba($color-black, .15);
  }
}

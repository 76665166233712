.simResultContainer {
  padding: 40px;
  padding-top: 180px;
  color: #000;

  p {
    color: #4a4a4a;
    font-size: 1.6rem;

    >span {
      font-weight: 600;
    }
  }

  .firstChild {
    padding-top: 10px;
  }

  .backButton {
    font-size: 1.6rem;
  }
}

.tcContainer {
  display: flex;
  flex-direction: column;
}

.tcStats {
  display: flex;

  >div:last-child {
    border-right: 1px solid rgb(219, 219, 219);
  }
}

.tcStats>* {
  border: 1px solid rgb(219, 219, 219);
  padding: 10px;
  width: 200px;
  border-right: none;
  border-bottom: none;
}

.tcStats:last-child>div {
  border-bottom: 1px solid rgb(219, 219, 219);
}


.statContainer {
  border: 1px solid rgb(219, 219, 219);
  box-shadow: 0 1.5rem 3rem rgb(0 0 0 / 5%);
  margin: 20px 0px;

  >h1 {
    border-bottom: 1px solid rgb(219, 219, 219);
    box-shadow: 0 1.5rem 3rem rgb(0 0 0 / 3%);
    padding: 10px;
  }

  >p {
    border-bottom: 1px solid rgb(219, 219, 219);
    padding: 20px;
    font-size: 2rem;
    font-weight: 600;

    &:last-child {
      border-bottom: none;
      font-weight: 400;
    }
  }


}

.newSimResultContainer {
  display: flex;
  flex-direction: column;
}

@media (min-width: 760px) {
  .newSimResultContainer {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0px 40px;
    justify-content: center;

    >* {
      flex-basis: 30%;
    }
  }
}
.RCwithDeckContainer {
  background-color: rgba(0, 0, 0, 0.556);
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 10px 15px;
  z-index: 10;
  border: 1px solid #000;
  width: 260px;

  h2 {
    font-size: 2rem !important;
    color: #EEE8AA
  }

  p {
    color: #EEE8AA;
    font-size: 1.1rem !important;
  }
}
@import '../../css/abstracts/hp-variables.scss';
.customRow:not(:last-child) {
  margin-bottom: 8rem;
}

.customRow {
  max-width: 114rem;
  margin: 0 auto;
}

@media only screen and (max-width: 56.25em) {
  .customRow {
    max-width: 80rem;
    padding: 0 3rem;
  }
}

.customCard {

  // FUNCTIONALITY
  // perspective: 80rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 55rem;
  max-width: 85%;
  margin: 0 auto;

  &__side {
    height: auto;
    transition: all 3s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 1.5rem 4rem rgba($color-black, .15);

    &front {
      background-color: $color-white;
    }

    &back {
      transform: rotateY(180deg);

      &1 {
        background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark);
      }

      &2 {
        background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark);
      }

      &3 {
        background-image: linear-gradient(to right bottom, $color-tertiary-light, $color-tertiary-dark);
      }
    }
  }


  // FRONT SIDE STYLING
  &__picture {
    background-size: cover;
    height: 23rem;
    background-blend-mode: screen;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    &1 {
      background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark);
    }
  }

  &__heading {
    font-size: 3.4rem;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    color: $color-white;
    position: absolute;
    top: 0rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  &__headingspan {
    padding: 1rem 1.5rem;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;

    &Price {
      font-weight: 400;
      font-size: 6rem;
    }
  }

  &__details {
    padding: 4rem;

    ul {
      list-style: none;
      width: 80%;
      margin: 0 auto;

      li {
        text-align: left;
        font-size: 2rem;
        padding: 1rem;

        &:not(:last-child) {
          border-bottom: 1px solid #ddd;
        }
      }
    }
  }

  .priceContainer {
    padding-top: 40px;
  }

  // FRONT SIDE STYLING
  &__cta {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center;
  }

  &__priceBox {
    text-align: center;
    color: $color-white;
    margin-bottom: 8rem;
  }

  &__priceOnly {
    font-size: 1.4rem;
    text-transform: uppercase;
  }

  &__priceValue {
    font-size: 6rem;
    font-weight: 100;
  }





  //@include respond(tab-port) {
  @media only screen and (max-width: 56.25em),
  only screen and (hover: none) {

    height: auto;
    border-radius: 3px;
    background-color: $color-white;
    box-shadow: 0 1.5rem 4rem rgba($color-black, .15);

    &__side {
      height: auto;
      position: relative;
      box-shadow: none;

      &--back {
        transform: rotateY(0);
        clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
      }
    }

    &:hover &__side--front {
      transform: rotateY(0);
    }


    &__details {
      padding: 1rem 3rem;
    }

    // FRONT SIDE STYLING
    &__cta {
      position: relative;
      top: 0%;
      left: 0;
      transform: translate(0);
      width: 100%;
      padding: 7rem 4rem 4rem 4rem;
    }

    &__price-box {
      margin-bottom: 3rem;
    }

    &__price-value {
      font-size: 4rem;
    }
  }

}

.btn {
  margin-top: 40px;
  &,
  &:link,
  &:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 2.5rem 6rem;
    display: inline-block;
    border-radius: 10rem;
    transition: all .2s;
    position: relative;
    font-size: 2.6rem;

    //Change for the <button> element
    border: none;
    cursor: pointer;
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);
  }

  &White {
    background-color: $color-white;
    color: $color-grey-dark;

    &::after {
      background-color: $color-white;
    }
  }

  &Green {
    background-color: $color-primary;
    color: $color-white;

    &::after {
      background-color: $color-primary;
    }
  }

  &Orange {
    background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark);
    color: $color-white;

    &::after {
      background-color: $color-primary;
    }
  }

  &::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 10rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .4s;
  }

  // &Animated {
  //   animation: moveInBottom .5s ease-out .75s;
  //   animation-fill-mode: backwards;
  // }
}

a:hover {
  color: #fff;
}

.btnText {

  &:link,
  &:visited {
    font-size: $default-font-size;
    color: $color-primary;
    display: inline-block;
    text-decoration: none;
    border-bottom: 1px solid $color-primary;
    padding: 3px;
    transition: all .2s;
  }

  &:active {
    box-shadow: 0 .5rem 1rem rgba($color-black, .15);
    transform: translateY(0);
  }
}
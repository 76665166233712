* {
    box-sizing: border-box;
    text-rendering: optimizeSpeed;
}

html.html-game {
    font-size: 16px; //1 rem = 10px; 10px/16px = 62.5%
}

body.body-game {
    margin: 0;
    padding: 0;
    font-size: 16px;
    position: relative;
    background-color: $body-bg-color;
    background-repeat: repeat;
    background-image: url('./images/bg.png');

    @media #{$retina} {
        background-image: url('./images/bg-retina.png');
        // todo: include ability in the build
        // system to read image dimension
        background-size: 100px 100px;
    }

    box-shadow: inset 1px 1px 120px 30px rgba(lighten(#000, 1%), 0.5);
    // testing use to be in html
    overflow: hidden;

    // line
    &:before {
        $line-color: darken($secondary-color, 5%);
        content: '';
        position: absolute;
        width: 100%;
        // blend with the inset box-shadow
        // from parent element
        z-index: -1;
        height: 2px;
        background: $line-color;
        bottom: 25%;
        // make it more realistic
        border-bottom: 1px solid darken($line-color, 50%);
        border-top: 1px solid lighten($line-color, 50%);
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
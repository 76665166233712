.navigation {
    &__checkbox {
        display: none;
    }

    &__button {
        background-color: #2E7E7E;
        height: 7rem;
        width: 7rem;
        position: fixed;
        top: 3rem;
        right: 2rem;
        border-radius: 50%;
        z-index: 20000;
        box-shadow: 0 1rem 3rem rgba($color-black, .1);
        text-align: center;
        cursor: pointer;

        // @include respond(tab-port) {
        //     top: 4rem;
        //     right: 4rem;
        // }

        // @include respond(phone) {
        //     top: 3rem;
        //     right: 3rem;
        // }
    }

    &__background {
        height: 6rem;
        width: 6rem;
        border-radius: 50%;
        position: fixed;
        top: 6.5rem;
        right: 6.5rem;
        background-image: radial-gradient($color-primary-light, $color-primary-dark);
        z-index: 1000;
        transition: transform .8s cubic-bezier(0.86, 0, 0.07, 1);

        @include respond(tab-port) {
            top: 4.5rem;
            right: 4.5rem;
        }

        @include respond(phone) {
            top: 3.5rem;
            right: 3.5rem;
        }
    }

    &__nav {
        visibility: hidden;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1500;

        opacity: 0;
        width: 100%;
        transition: all 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    &__list {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        list-style: none;
        text-align: center;
        width: 100%;
        padding-left: 0px;
    }

    &__item {
        margin: 1rem;
    }

    &__link {

        &:link,
        &:visited {
            display: inline-block;
            font-size: 3rem;
            font-weight: 300;
            padding: 1rem 2rem;
            color: $color-white;
            text-decoration: none;
            text-transform: uppercase;
            background-image: linear-gradient(120deg, transparent 0%, transparent 50%, $color-white 50%);
            background-size: 220%;
            transition: all .4s;

            span {
                margin-right: 1.5rem;
                display: inline-block;
            }
        }

        &:hover,
        &:active {
            background-position: 100%;
            color: $color-primary;
            transform: translateX(1rem);
        }
    }


    //FUNCTIONALITY
    &__checkbox:checked~&__background {
        transform: scale(80);
    }

    &__checkbox:checked~&__nav {
        opacity: 1;
        width: 100%;
        visibility: visible;
    }


    //ICON
    &__icon {
        position: relative;
        margin-top: 3.5rem;

        &,
        &::before,
        &::after {
            width: 3rem;
            height: 2px;
            background-color: #fff;
            display: inline-block;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
            transition: all .2s;
        }

        &::before {
            top: -.8rem;
        }

        &::after {
            top: .8rem;
        }
    }

    &__button:hover &__icon::before {
        top: -1rem;
    }

    &__button:hover &__icon::after {
        top: 1rem;
    }

    &__checkbox:checked+&__button &__icon {
        background-color: transparent;
    }

    &__checkbox:checked+&__button &__icon::before {
        top: 0;
        transform: rotate(135deg);
    }

    &__checkbox:checked+&__button &__icon::after {
        top: 0;
        transform: rotate(-135deg);
    }
}

@media (min-width: 600px) {
    .navigation {
        display: none;
    }
}
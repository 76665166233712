@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600&display=swap');

.faqSection {
  padding-top: 20px;

  >* {
    flex: 1 1 35%;
  }
}

.faqContainer {
  background: #E7E7E7;
  width: 100%;
  font-family: 'Oswald', sans-serif;
  color: #000;
  padding: 40px;

  h3 {
    font-weight: 300;
    font-size: 4rem
  }

  h2 {
    text-transform: uppercase;
  }

  h1 {
    font-size: 5rem;
    font-weight: 600;
  }

  p {
    font-weight: 300;
    font-size: 2rem;
    font-stretch: ultra-expanded;
  }

  .faqNote {
    padding-top: 40px;
  }
}

.faqHeading {
  text-align: center;
}

@media (min-width: 900px) {
  .faqSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px 40px;

    >* {
      flex: 0 1 35%;
    }
  }

  .faqContainer {
    background: #E7E7E7;
    width: 100%;
    font-family: 'Oswald', sans-serif;
    color: #000;
    padding-top: 40px;
    padding-bottom: 40px;
    font-stretch: expanded;

    h3 {
      font-weight: 300;
      font-size: 4rem
    }

    h2 {
      text-transform: uppercase;
    }

    h1 {
      font-size: 5rem;
      font-weight: 600;
    }

    p {
      font-weight: 300;
      font-size: 2rem;
      font-stretch: ultra-expanded;
    }

    .faqNote {
      padding-top: 40px;
    }
  }
}
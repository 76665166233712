.main {
  display: block;
  padding: 20px;
  padding-top: 120px;
  color: #4a4a4a;
  width: 100%;
  margin: 0 auto;


  .termsOfUse {
    flex-basis: 100%;
    padding: 20px;
    padding-top: 40px;

    >p {
      font-size: 2rem;
      margin-top: 20px;
    }
  }


  h2 {
    font-size: 3rem;
  }
}

@media (min-width: 600px) {
  .main {
    width: 80%;
    padding-top: 160px;
  }
}

@media (min-width: 800px) {
  .main {
    width: 60%;
  }
}
.preloader {
  background: #000 url(images/circle-loader.gif) no-repeat center center;
  background-size: 15%;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 100;
  opacity: 1;
  animation-name: removeLoader;
  animation-duration: 1.4s;
  animation-delay: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes removeLoader {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    visibility: hidden
  }
}

@media (max-width: 510px) {
  .preloader {
    background-size: 55%;
  }
}
@import '../abstracts/hp-variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap');

.headingPrimary {
  color: $color-white;
  text-transform: uppercase;

  backface-visibility: hidden;
  margin-bottom: 6rem;

  &Main {
    display: block;
    font-size: 6rem;
    font-weight: 400;
    letter-spacing: 3.5rem;

    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;

    // @include respond(phone) {
    //   letter-spacing: 1rem;
    //   font-family: 5rem;
    // }

    /*
        animation-delay: 3s;
        animation-iteration-count: 3;
        */
  }

  &Sub {
    display: block;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 1.75rem;
    animation: moveInRight 1s ease-out;

    // @include respond(phone) {
    //   letter-spacing: .5rem;
    // }
  }
}

.headingSecondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(to right, $color-primary-light, $color-primary-dark);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: .2rem;
  transition: all 1s;

  // @include respond(tab-port) {
  //   font-size: 3rem;
  // }

  // @include respond(phone) {
  //   font-size: 2.5rem;
  // }
}

.headingTertiary {
  font-size: $default-font-size;
  font-weight: 700;
  text-transform: uppercase;
}

.paragraph {
  font-size: $default-font-size;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}

.pages {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  /*font-size: 16px;*/
  line-height: 1.7;
  color: $color-grey-dark;
}

.headingPrimary {
  color: $color-white;
  text-transform: uppercase;

  backface-visibility: hidden;
  margin-bottom: 6rem;

  &Main {
    display: block;
    font-size: 6rem;
    font-weight: 400;
    letter-spacing: 3.5rem;

    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;

    // @include respond(phone) {
    //   letter-spacing: 1rem;
    //   font-family: 5rem;
    // }

    /*
        animation-delay: 3s;
        animation-iteration-count: 3;
        */
  }

  &Sub {
    display: block;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 1.75rem;
    animation: moveInRight 1s ease-out;

    // @include respond(phone) {
    //   letter-spacing: .5rem;
    // }
  }
}

.headingSecondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(to right, $color-primary-light, $color-primary-dark);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: .2rem;
  transition: all 1s;

  // @include respond(tab-port) {
  //   font-size: 3rem;
  // }

  // @include respond(phone) {
  //   font-size: 2.5rem;
  // }
}

.headingTertiary {
  font-size: $default-font-size;
  font-weight: 700;
  text-transform: uppercase;
}

.paragraph {
  font-size: $default-font-size;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}
@import 'variables.scss';

.gameModal {
  max-width: 50%;
  min-width: 65%;
  background-color: rgba(0, 0, 0, 0.556);
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 4px 0px;
  padding-bottom: 10px;
  z-index: 10;
  border: 1px solid #000;

  h2 {
    font-size: 2rem !important;
    color: #EEE8AA
  }

  p {
    color: #EEE8AA;
    font-size: 1.1rem !important;
  }

  input {
    padding: 6px;
    margin: 10px;
    margin-bottom: 12px;
    width: 80%;
  }
}

.hideModal {
  display: none;
}

.backDrop {
  background-color: darkorange;
  background-image: linear-gradient(130deg, #ff7a18,
      #af002d 41.07%,
      #319197 76.05%);
  opacity: .4;
  width: 100%;
  height: 100%;
  position: absolute;
}

.gameModal::backdrop {
  background-color: darkorange;
  background-image: linear-gradient(130deg, #ff7a18,
      #af002d 41.07%,
      #319197 76.05%);
  opacity: .4
}

.btn {



  &,
  &:link,
  &:visited {
    text-decoration: none;
    padding: .6rem 1.6rem;
    display: inline-block;
    border-radius: 10rem;
    transition: all .2s;
    position: relative;
    font-size: 1.2rem;

    //Change for the <button> element
    border: none;
    cursor: pointer;
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 .5rem 1rem rgba($color-black, .2);
  }

  &Green {
    background-color: $color-primary;
    color: $color-white;

    &::after {
      background-color: $color-primary;
    }
  }

  &::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 10rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .4s;
  }

  &Dismiss {
    margin-top: 15px;
    padding: .6rem 1.6rem !important
  }

  // &Animated {
  //   animation: moveInBottom .5s ease-out .75s;
  //   animation-fill-mode: backwards;
  // }
}

.btnText {

  &:link,
  &:visited {
    font-size: $default-font-size;
    color: $color-primary;
    display: inline-block;
    text-decoration: none;
    border-bottom: 1px solid $color-primary;
    padding: 3px;
    transition: all .2s;
  }

  &:active {
    box-shadow: 0 .5rem 1rem rgba($color-black, .15);
    transform: translateY(0);
  }
}

.intputTC {
  background-color: rgb(255 255 255 / 22%)
}

.inputTC::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #EEE8AA;
  opacity: 1;
  font-size: 1.2rem;
  /* Firefox */
}

.inputTC:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #EEE8AA;
}

.inputTC::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #EEE8AA;
}

@media (min-width: 440px) {

  .inputTC::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */

    /* Firefox */
  }

  .inputTC:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
  }

  .inputTC::-ms-input-placeholder {
    /* Microsoft Edge */
  }

  .btn {

    &,
    &:link,
    &:visited {
      padding: .9rem 1.4rem;
    }


  }

  .gameModal {
    padding: 20px;
    max-width: 20%;
    min-width: 300px;

    input {
      padding: 15px;
      margin: 10px;
      margin-bottom: 20px;
    }
  }
}
@font-face {
  font-family: 'Montserrat';
  src: url('../pages/css/fonts/Montserrat-VariableFont_wght.ttf');
  font-weight: normal;
  font-style: normal;
}

.pagesContainer {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  /*font-size: 16px;*/
  line-height: 1.7;
  color: #999;
  opacity: 1;
}
.header {
    height: 85vh;
    background-image: linear-gradient(0deg, rgba(21, 4, 32, 0.94) 0%, rgba(2, 83, 88, 0.99) 88%);
    background-size: cover;
    background-position: top;
    position: relative;

    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
        // -webkit-clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);
        // clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);
        height: 95vh;
    }

    @media only screen and (min-resolution: 192dpi) and (min-width: 37.5em),
            only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em),
            only screen and (min-width: 125em) {
        background-image: linear-gradient(0deg, rgba(21, 4, 32, 0.94) 0%, rgba(2, 83, 88, 0.99) 88%);
    }

    @include respond(phone) {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
    }

    &__logo-box {
        position: absolute;
        top: 4rem;
        left: 4rem;
    }

    &__logo {
        height: 3.5rem;
    }

    &__text-box {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
}